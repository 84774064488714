import { Grid, Typography, Button, Card, CardContent } from "@mui/material";
import React from "react";
import LeftIcon from '../../../assets/icons/LeftIcon.png';
import RightIcon from '../../../assets/icons/RightIcon.png';
import infoIcon from '../../../assets/icons/infoIcon.png';
import Frame2 from '../../../assets/icons/Frame2.png';
import Frame1 from '../../../assets/icons/Frame1.png';
import Frame4 from '../../../assets/icons/Frame4.png';
import Frame3 from '../../../assets/icons/Frame3.png';
import infoIcon2 from '../../../assets/icons/infoIcon2.png';
import styles from './step.module.scss';

const Step4 = ({ stepFourSubmit, ...other }) => {
    const { formValue, setFormValue, step, setStep } = other;

    const onSubmit = () => {
        stepFourSubmit();
    };

    console.log(formValue, 'formValue');



    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                    <section className={styles.subContainer}>
                        <Typography className={styles.title}>ADU Preferences</Typography>
                        <Typography className={styles.subTitle}>You are able to build up to 1 Accessory Dwelling Unit and 1 Junior Accessory Dwelling Unit.</Typography>
                        <div className={styles.selectAdu}>
                            <section className={styles.selectTitleContainer}>
                                <Typography className={styles.selectTitle}>Select ADU type </Typography>
                                <img src={infoIcon} alt="" />
                            </section>
                            <Typography className={styles.selectSubTitle}>Lorem ipsum dolor sit amet, et nomine patri.</Typography>
                            <section className={styles.selectCardContainer}>
                            <label>
                                <Card className={`${formValue.aduType1 === "ADU" ? (styles.accessoryActiveCard) : (styles.accessoryInActiveCard)}`}>
                              
                                    <section className={styles.checkBoxContainer}>
                                     
                                        <input
                                            type="checkbox"
                                            checked={formValue.aduType1 === "ADU"}
                                            value={formValue.aduType1}
                                            onChange={(e) => { setFormValue({ ...formValue, aduType1: "ADU" }) }}
                                        />
                                 
                                        </section>
                                    
                                    <CardContent className={styles.accessoryContent}>
                                        <img src={Frame1} alt='' />
                                        <Typography className={styles.accessoryCardTitle}>Accessory Dwelling Unit</Typography>
                                    </CardContent>
                                </Card>
                                </label>
                                <label>
                                <Card className={`${formValue.aduType1 === "JADU" ? (styles.accessoryActiveCard) : (styles.accessoryInActiveCard)}`}>
                                    <section className={styles.checkBoxContainer}>
                                        <input
                                            type="checkbox"
                                            checked={formValue.aduType1 === "JADU"}
                                            value={formValue.aduType1}
                                            onChange={(e) => { setFormValue({ ...formValue, aduType1: "JADU" }) }}
                                        />

                                    </section>
                               
                                    <CardContent className={styles.accessoryContent}>
                                        <img src={Frame2} alt='' />
                                        <Typography className={styles.accessoryCardTitle}>Junior Accessory
                                            Dwelling Unit</Typography>
                                    </CardContent>
                                </Card>
                                </label>
                                <label>
                                <Card className={`${formValue.aduType1 === "not sure" ? (styles.lastActiveCard):
                                (styles.lastInActiveCard)}`} >
                                    <section className={styles.lastCheckBoxContainer}>
                                        <input
                                            type="checkbox"
                                            checked={formValue.aduType1 === "not sure"}
                                            value={formValue.aduType1}
                                            onChange={(e) => { setFormValue({ ...formValue, aduType1: "not sure" }) }}
                                        />

                                    </section>
                                    <CardContent className={styles.lastCardContent} >
                                        <img src={infoIcon2} alt='' />
                                        <Typography className={styles.lastCardTitle}>I’m not sure</Typography>
                                    </CardContent>
                                </Card>
                                </label>
                            </section>
                        </div>
                        <div className={styles.selectAdu}>
                            <section className={styles.selectTitleContainer}>
                                <Typography className={styles.selectTitle}>Make your selection </Typography>
                            </section>
                            <Typography className={styles.selectSubTitle}>Lorem ipsum dolor sit amet, et nomine patri.</Typography>
                            <section className={styles.selectCardContainer}>
                                <label>
                                <Card className={`${formValue.aduType2 === "Garage" ? (styles.accessoryActiveCard) : (styles.accessoryInActiveCard)}`}>
                                    <section className={styles.checkBoxContainer}>      <input
                                        type="checkbox"
                                        checked={formValue.aduType2 === "Garage"}
                                        value={formValue.aduType2}
                                        onChange={(e) => { setFormValue({ ...formValue, aduType2: "Garage" }) }}
                                    /> </section>
                                    <CardContent className={styles.accessoryContent}>
                                        <img src={Frame2} alt='' />
                                        <Typography className={styles.accessoryCardTitle}>Garage</Typography>
                                    </CardContent>
                                </Card>
                                </label>

                                <label>
                                <Card className={`${formValue.aduType2 === "Detached" ? (styles.accessoryActiveCard) : (styles.accessoryInActiveCard)}`}>
                                    <section className={styles.checkBoxContainer}>
                                        <input
                                            type="checkbox"
                                            checked={formValue.aduType2 === "Detached"}
                                            onChange={(e) => { setFormValue({ ...formValue, aduType2: "Detached" }) }}
                                        /></section>
                                    <CardContent className={styles.accessoryContent}>
                                        <img src={Frame4} alt='' />
                                        <Typography className={styles.accessoryCardTitle}>Detached</Typography>
                                    </CardContent>
                                </Card>
                                </label>

                                <label>
                                <Card className={`${formValue.aduType2 === "Above Garage" ? (styles.accessoryActiveCard) : (styles.accessoryInActiveCard)}`}>
                                    <section className={styles.checkBoxContainer}> <input
                                        type="checkbox"
                                        checked={formValue.aduType2 === "Above Garage"}
                                        onChange={(e) => { setFormValue({ ...formValue, aduType2: "Above Garage" }) }}
                                    /></section>
                                    <CardContent className={styles.accessoryContent}>
                                        <img src={Frame3} alt='' />
                                        <Typography className={styles.accessoryCardTitle}>Above Garage</Typography>
                                    </CardContent>
                                </Card>
                                </label>
                            </section>
                        </div>
                        <div className={styles.specialContainer}>
                            <Typography className={styles.specialTitle}>Do you know what are you looking specifically?</Typography>
                            <Typography className={styles.specialSubTitle}>Regarding size, bedroom and bathroom count, or would you like us to provide you with options?</Typography>
                            <section className={styles.selectInputContainer} >
                                <Button variant="outlined"
                                    value={formValue.specific}
                                    onClick={(e) => { setFormValue({ ...formValue, specific: "Whatever I can do" }) }}
                                    fullWidth className={`${formValue.specific === "Whatever I can do" ? (styles.specialLeftActiveBtn) : (styles.specialLeftInActiveBtn)}`}>Whatever I can do</Button>
                                <Button variant="outlined"
                                    value={formValue.specific}
                                    onClick={(e) => { setFormValue({ ...formValue, specific: "Maximum Size" }) }}
                                    fullWidth className={`${formValue.specific === "Maximum Size" ? (styles.specialRightBtnActiveBtn) : (styles.specialRightBtnInActiveBtn)}`}>Maximum Size</Button>
                            </section>
                            <section className={styles.selectInputContainer}>
                                <Button variant="outlined"
                                    value={formValue.specific}
                                    onClick={(e) => { setFormValue({ ...formValue, specific: "Not sure, please make a recommendation" }) }}
                                    fullWidth className={`${formValue.specific === "Not sure, please make a recommendation" ? (styles.specialLeftActiveBtn) : (styles.specialLeftInActiveBtn)}`}>Not sure, please make a recommendation</Button>
                                <Button variant="outlined"
                                    value={formValue.specific}
                                    onClick={(e) => { setFormValue({ ...formValue, specific: "I would like to select options  my ADU" }) }}
                                    fullWidth className={`${formValue.specific === "I would like to select options  my ADU" ? (styles.specialRightBtnActiveBtn) : (styles.specialRightBtnInActiveBtn)}`}>I would like to select options for my ADU</Button>
                            </section>
                        </div>
                        <div className={styles.btnContainer}>
                            <Button variant="outlined" className={styles.backBtn} onClick={() => setStep(step - 1)}><img src={LeftIcon} alt="" /> BACK </Button>
                            <Button variant="contained" className={styles.nextBtn} onClick={onSubmit}>NEXT <img src={RightIcon} alt="" /></Button>
                        </div>
                    </section>
                </div>
            </Grid>
        </Grid>
    )
}

export default Step4;