import './App.css';
import React from 'react';
import RoutingPages from './routes/routes';
import { Provider } from 'react-redux';
import store from './store';

const App = () => {
  return (
    <Provider store={store}>
      <RoutingPages />
    </Provider>
  )
}

export default App;