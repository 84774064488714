import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography, Stack, Step, StepLabel, Stepper } from '@mui/material';
import home1 from '../../Icons/home1.png';
import setting1 from '../../Icons/setting1.png';
import user1 from '../../Icons/user1.png';
import getreport1 from '../../Icons/getreport1.png'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(rgb(251, 101, 31), rgb(251, 101, 31))',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( rgb(251, 101, 31),rgb(251, 101, 31))',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? '#EBEAEC' : '#EBEAEC',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
  zIndex: 1,
  color: '#FFFFFF',
  width: 38,
  height: 38,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(rgb(251, 101, 31), rgb(251, 101, 31))',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(rgb(251, 101, 31), rgb(251, 101, 31))',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <img src={home1} />,
    2: <img src={setting1} />,
    3: <img src={user1} />,
    4: <img src={getreport1} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Enter Address",
  "ADU Preferences",
  "Contact & Payment Info",
  "Get Report Results",
];

export default function CustomizedSteppers({ step }) {
  return (
    <Stack sx={{ width: '100%' }} spacing={1}>
      <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}><Typography sx={{ color: "#FFFFFF", fontSize: '1vw' }}>{label}</Typography></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
