import * as React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Box} from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import CustomizedSteppers from '../stepper/stepper';

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function StepperComp({step, props}) {
  return (
    <div>
      <ElevationScroll {...props}>
        <AppBar position='static'>
          <Toolbar sx={{backgroundColor: '#0F2453', pt:2, pb:2, alignItems: 'center', justifyContent: 'center'}}>
                <Box sx={{width: '70%'}}>
                <CustomizedSteppers step={step}/>
                </Box>                                  
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </div>
  );
}
