import NavBar from '../../Components/navbar/navbar';
import StepperComp from '../../Components/stepperComp/stepperComp';
import Container from '@mui/material/Container';
import styles from './cancel.module.scss';
import React from 'react';
import Cancel from '../../Components/CancelPage/Cancel';

const CancelPage = () => {

    return (
        <div className={styles.mainContainer}>
            <NavBar />
            <div style={{position: "fixed", width: "100%", overFlow: "hidden", zIndex: 1000}}>
          <StepperComp step={3} />
          </div>
            <Container sx={{mt: 18}}>
                <Cancel />
            </Container>
        </div>
    )
}

export default CancelPage;