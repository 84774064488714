import React, { useState } from "react";
import tick from '../../Icons/tick.png'
import share from '../../Icons/share.png';
import summryFrame from '../../Icons/summryFrame.png';
import { Grid, Typography, Card, Button } from '@mui/material';
import { PopupButton, InlineWidget } from "react-calendly";
import styles from './success.module.scss';


const Success = () => {
     let url = useState('https://calendly.com/discovery-call-adugeeks/15-minute-discovery-call?month=2022-09');
    return (
    
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                    <section className={styles.subContainer}>
                        <div className={styles.reportContainer}>
                            <Typography className={styles.reportTitle}>Your report is ready</Typography>
                            <Typography className={styles.reportSubTitle}>You are one step closer to lorem ipsum dolor sit amet.</Typography>
                            <Card className={styles.reportCard}>
                            <img src={tick} alt=''/>
    
                            <Typography className={styles.reportName}>Lorem Ipsum dolor sit amet et nomine </Typography>
                            <section className={styles.shareTitle}> 
                            {/* <img src={share} alt="" /> */}
                            </section>    
                            
                            </Card>
                            <div className={styles.btnContainer}>
                                      <PopupButton
                                    url={url}
                                    rootElement={document.getElementById("root")}
                                    text="SCHEDULE A FREE CONSULTATION"
                                    className={styles.consultationBtn}
                                />
                                {/* <Button variant="contained" fullWidth className={styles.consultationBtn}>SCHEDULE A FREE CONSULTATION</Button> */}
                                 {/* <Button variant="outlined" fullWidth className={styles.downloadBtn}>DOWNLOARD REPORT</Button>  */}
                            </div>
                        </div>
                        <div className={styles.rightImgContainer}>
                            <img src={summryFrame} alt="" />
                        </div>
                    </section>
                </div>
            </Grid>
        </Grid>)
}


export default Success;