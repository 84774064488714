import { Button, Card, Grid, Typography, FormGroup, FormControlLabel, Box, LinearProgress } from "@mui/material";
import { createMuiTheme, ThemeProvider } from '@mui/material/styles';
import React from "react";
import RightIcon from '../../../assets/icons/RightIcon.png';
import pointers from '../../../assets/icons/pointers.png'
import styles from './step.module.scss';

const Step1 = ({ stepOneSubmit, ...other }) => {

    const { formValue, setFormValue } = other;
    const [progress, setProgress] = React.useState(0);
    const onSubmit = () => {
        stepOneSubmit();
    };

    const theme = createMuiTheme({
        typography: {
            fontFamily: [
                "barlowregular"
            ].join(','),
        },
    });



    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 25);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <div className='stepper'>
                        <Box>
                            <LinearProgress variant="determinate" value={progress} className='stepper' />
                        </Box>
                    </div>
                    <div className={styles.mainContainer}>
                        <div className={styles.subContainer}>
                            <Typography className={styles.title}>Get to know the best possibilities for ADU
                                construction in your property
                            </Typography>
                            <Typography className={styles.subTitle}>This is the address where you plan to build your ADU.
                            </Typography>
                            <Card className={styles.card}>
                                <ThemeProvider theme={theme}>
                                    <FormGroup sx={{ fontFamily: 'barlowregular' }}>

                                        <FormControlLabel control={<img src={pointers} className={styles.pointIcon} />} label="Lorem Ipsum dolor sit amet et nomine et. " />
                                        <FormControlLabel control={<img src={pointers} className={styles.pointIcon} />} className={styles.formContent} label="Lorem Ipsum dolor sit amet et nomine et. " />
                                        <FormControlLabel control={<img src={pointers} className={styles.pointIcon} />} className={styles.formContent} label="Lorem Ipsum dolor sit amet et nomine et. " />
                                        <FormControlLabel control={<img src={pointers} className={styles.pointIcon} />} className={styles.formContent} label="Lorem Ipsum dolor sit amet et nomine et. " />

                                    </FormGroup >
                                </ThemeProvider>
                            </Card>
                            <Button variant="contained" size="large" className={styles.button} onClick={onSubmit} >Start Now <img src={RightIcon} alt='' /></Button>
                        </div>
                    </div>
                </Grid>
            </Grid >
    );
};

export default Step1;
