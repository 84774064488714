import { Grid, Typography, Button, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import LeftIcon from '../../../assets/icons/LeftIcon.png';
import RightIcon from '../../../assets/icons/RightIcon.png';
import styles from './step.module.scss';

const Step6 = ({ stepSixSubmit, ...other }) => {
    const { formValue, setFormValue, step, setStep } = other;

    const theme = createTheme({
        palette: {
            primary: {
                main: '#212121',
            }
        }
    });

    const handleChange = e => {
        const newValue = e.target.value;
        if (/^\d{0,10}$/.test(newValue)) {
            setFormValue({ ...formValue, phoneNumber: newValue });
        }
    };

    const onSubmit = () => {
        stepSixSubmit();
    };
    console.log(formValue, 'formValue')
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                    <section className={styles.subContainer}>
                        <Typography className={styles.title}>Contact Information</Typography>
                        <Typography className={styles.subTitle}>Lorem ipsum dolor sit amet et nomine patri, et sanctis nom, et fili nomine sacti.</Typography>
                        <div className={styles.contactContainer}>
                            <section className={styles.inputFields}>
                                <ThemeProvider theme={theme}>
                                    <TextField focused label="First Name"
                                        placeholder={formValue.firstName}
                                        value={formValue.firstName}
                                        onChange={(e) =>
                                            setFormValue({ ...formValue, firstName: e.target.value })}
                                        size="small" fullWidth
                                        className={styles.firstName}
                                        InputLabelProps={{ style: { fontFamily: 'barlowregular' } }}
                                        sx={{ input: { color: '#212121', fontFamily: 'barlowregular', fontSize: '16px',fontWeight:'400' } }}
                                    />
                                    <TextField
                                        focused label="Last Name"
                                        placeholder={formValue.lastName}
                                        value={formValue.lastName}
                                        onChange={(e) =>
                                            setFormValue({ ...formValue, lastName: e.target.value })}
                                        size="small" fullWidth className={styles.lastName}
                                        InputLabelProps={{ style: { fontFamily: 'barlowregular' } }}
                                        sx={{ input: { color: '#212121', fontFamily: 'barlowregular', fontSize: '16px',fontWeight:'400' } }}
                                    />
                                </ThemeProvider>
                            </section>
                            <section className={styles.inputFields}>
                                <ThemeProvider theme={theme}>

                                    <TextField focused label="Email Address"
                                        value={formValue.emailId}
                                        placeholder={formValue.emailId}
                                        onChange={(e) =>
                                            setFormValue({ ...formValue, emailId: e.target.value })}
                                        size="small" fullWidth
                                        className={styles.firstName}
                                        InputLabelProps={{ style: { fontFamily: 'barlowregular' } }}
                                        sx={{ input: { fontFamily: 'barlowregular',fontWeight:'400' } }}

                                    />
                                    <TextField focused
                                        label="Phone Number"
                                        placeholder={formValue.phoneNumber}
                                        type="text"
                                        value={formValue.phoneNumber}
                                        onChange={handleChange}
                                        size="small" fullWidth
                                        className={styles.lastName}
                                        InputLabelProps={{ style: { fontFamily: 'barlowregular' } }}
                                        sx={{ input: { color: '#212121', fontFamily: 'barlowregular', fontSize: '16px',fontWeight:'400'} }}

                                    />
                                </ThemeProvider>
                            </section>

                        </div>
                       
                       
                        <div className={styles.btnContainer}>
                            <Button variant="outlined" className={styles.backBtn} onClick={() => setStep(step - 1)}><img src={LeftIcon} alt="" /> BACK </Button>
                            <Button variant="contained" className={styles.nextBtn} onClick={onSubmit}>NEXT <img src={RightIcon} alt="" /></Button>
                        </div>
                    </section>
                </div>
            </Grid>
        </Grid>
    )
}

export default Step6;