import {
  Card,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel,
} from "@mui/material";
import Slide from "../../slider/slide";
import LeftIcon from "../../../assets/icons/LeftIcon.png";
import RightIcon from "../../../assets/icons/RightIcon.png";
import styles from "./step.module.scss";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Step5 = ({ stepFiveSubmit, ...other }) => {
  const { formValue, setFormValue, step, setStep } = other;
  const theme = createTheme({
    palette: {
      primary: {
        main: "#212121",
      },
    },
  });

  const onSubmit = () => {
    stepFiveSubmit();
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <div className={styles.mainContainer}>
          <section className={styles.subContainer}>
            <Typography className={styles.title}>
              Select your ADU options
            </Typography>
            <Typography className={styles.subTitle}>
              Lorem ipsum dolor sit amet et nomine patri, et sanctis nom, et
              fili nomine sacti.
            </Typography>
            <div className={styles.optionContainer}>
              <Card className={styles.cardOne}>
                <Typography className={styles.cardTitle}>
                  Option 1 ADU
                </Typography>
                <Typography className={styles.cardSubTitle}>
                  Select your preferences.
                </Typography>
                <div className={styles.feetContainer}>
                  <Typography className={styles.feetTitle}>
                    Square feet
                  </Typography>
                  <Typography className={styles.maxTitle}>
                    (Max.1200)
                  </Typography>
                </div>
                <div className={styles.slider}>
                  {" "}
                  <Slide
                    defaultValue={500}
                    step={250}
                    min={0}
                    max={1200}
                    value={formValue.sqft1}
                    handleChange={(e) => {
                      setFormValue({ ...formValue, sqft1: e.target.value, priceId: "price_1M90a4GUWr06e0k5LKr1Mp2J" });
                    }}
                  />
                </div>
                <div className={styles.checbBoxContainer}>
                  <input
                    type="checkbox"
                     id="option1"
                      name="option1"
                       value="I want a Professional Recommendation"
                        className={styles.checkBtn} />
                  <label for="option1" className={styles.checkLabel}> I want a Professional Recommendation</label>
             
         
                  {/* <label className={styles.check}>
                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />

                    <Typography className={styles.checkTitle}>
                      I want a Professional Recommendation{" "}
                    </Typography>
                  </label> */}

                </div>
                <div className={styles.roomSelectContainer}>
                  <Typography className={styles.roomTitle}>Bedrooms</Typography>
                  <FormControl fullWidth>
                    <ThemeProvider theme={theme}>
                      <InputLabel>Please select..</InputLabel>
                      <Select
                        className={styles.drop}
                        value={formValue.aduBedroom}
                        label="Please select.."
                        sx={{ color: "#21212199", fontFamily: "barlowregular" }}
                        onChange={(e) => {
                          setFormValue({
                            ...formValue,
                            aduBedroom: e.target.value,
                          });
                        }}
                        // onChange={hanldeBedRoom}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem
                          value={1}
                          className={styles.drop}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          1
                        </MenuItem>
                        <MenuItem
                          value={2}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          2
                        </MenuItem>
                        <MenuItem
                          value={3}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          3
                        </MenuItem>
                        <MenuItem
                          value={4}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          4
                        </MenuItem>
                        <MenuItem
                          value={"studio"}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          Studio
                        </MenuItem>
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </div>
                <div className={styles.roomSelectContainer}>
                  <Typography className={styles.roomTitle}>
                    Bathrooms
                  </Typography>
                  <FormControl fullWidth>
                    <ThemeProvider theme={theme}>
                      <InputLabel>Please select..</InputLabel>
                      <Select
                        label="Please select.."
                        value={formValue.aduBathroom}
                        sx={{ color: "#21212199", fontFamily: "barlowregular" }}
                        onChange={(e) => {
                          setFormValue({
                            ...formValue,
                            aduBathroom: e.target.value,
                          });
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem
                          value={1}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          1
                        </MenuItem>
                        <MenuItem
                          value={2}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          2
                        </MenuItem>
                        <MenuItem
                          value={3}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          3
                        </MenuItem>
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </div>
              </Card>
              <Card className={styles.cardTwo}>
                <Typography className={styles.cardTitle}>
                  Option 2 JADU
                </Typography>
                <Typography className={styles.cardSubTitle}>
                  Select your preferences.
                </Typography>
                <div className={styles.feetContainer}>
                  <Typography className={styles.feetTitle}>
                    Square feet
                  </Typography>
                  <Typography className={styles.maxTitle}>(Max.500)</Typography>
                </div>
                <div className={styles.slider}>
                  <Slide
                    defaultValue={250}
                    step={100}
                    min={0}
                    max={500}
                    value={formValue.sqft2}
                    handleChange={(e) => {
                      setFormValue({ ...formValue, sqft2: e.target.value, priceId: "price_1M8zRZGUWr06e0k5bgwLRxkv" });
                    }}
                  />
                </div>
                <div className={styles.checbBoxContainer}>
                <input
                    type="checkbox"
                     id="option2"
                      name="option2"
                       value="I want a Professional Recommendation"
                        className={styles.checkBtn} />
                  <label for="option2" className={styles.checkLabel}> I want a Professional Recommendation</label>
                  {/* 
                  <label className={styles.check}>

                    <input
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <Typography className={styles.checkTitle}>
                      I want a Professional Recommendation
                    </Typography>
                  </label> */}

                </div>
                <div className={styles.roomSelectContainer}>
                  <Typography className={styles.roomTitle}>Bedrooms</Typography>
                  <FormControl fullWidth>
                    <ThemeProvider theme={theme}>
                      <InputLabel>Please select..</InputLabel>
                      <Select
                        value={formValue.jaduBedroom}
                        label="Please select.."
                        sx={{ color: "#21212199", fontFamily: "barlowregular" }}
                        onChange={(e) => {
                          setFormValue({
                            ...formValue,
                            jaduBedroom: e.target.value,
                          });
                        }}
                        // onChange={hanldeBedRoom}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem
                          value={1}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          1
                        </MenuItem>
                        <MenuItem
                          value={2}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          2
                        </MenuItem>
                        <MenuItem
                          value={3}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          Studio
                        </MenuItem>
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </div>
                <div className={styles.roomSelectContainer}>
                  <Typography className={styles.roomTitle}>
                    Bathrooms
                  </Typography>
                  <FormControl fullWidth>
                    <ThemeProvider theme={theme}>
                      <InputLabel>Please select..</InputLabel>
                      <Select
                        label="Please select.."
                        value={formValue.jaduBathroom}
                        sx={{ color: "#21212199", fontFamily: "barlowregular" }}
                        onChange={(e) => {
                          setFormValue({
                            ...formValue,
                            jaduBathroom: e.target.value,
                          });
                        }}
                        // onChange={handleBathRoom}

                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem
                          value={1}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          1
                        </MenuItem>
                        <MenuItem
                          value={2}
                          sx={{
                            color: "#21212199",
                            fontFamily: "barlowregular",
                          }}
                        >
                          2
                        </MenuItem>
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </div>
              </Card>
            </div>
            <div className={styles.btnContainer}>
              <Button
                variant="outlined"
                className={styles.backBtn}
                onClick={() => setStep(step - 1)}
              >
                <img src={LeftIcon} alt="" /> BACK{" "}
              </Button>
              <Button
                variant="contained"
                className={styles.nextBtn}
                onClick={onSubmit}
              >
                NEXT <img src={RightIcon} alt="" />
              </Button>
            </div>
          </section>
        </div>
      </Grid>
    </Grid>
  );
};

export default Step5;
