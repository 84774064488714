import { Grid, Typography, FormControl, Button, Select, MenuItem, Chip, InputLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import LeftIcon from '../../../assets/icons/LeftIcon.png';
import RightIcon from '../../../assets/icons/RightIcon.png';
import styles from './step.module.scss';
import FamilyZoning from '../../assets/icons/FamilyZoning'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Step3 = ({ stepThreeSubmit, ...other }) => {
    const { formValue, setFormValue, step, setStep } = other;
    const [selectedButtons, setSelectedButtons] = useState(formValue.servicesData);

    const handleButtonClick = (e) => {
        const id = e.target.id;
        const isSelected = selectedButtons.includes(id);
        if (isSelected) {
            setSelectedButtons(selectedButtons.filter((b) => b !== id));
        } else {
            setSelectedButtons([...selectedButtons, id]);
        }
    }

    const onSubmit = () => {
        stepThreeSubmit();
    };
    const theme = createTheme({
        palette: {
            primary: {
                main: '#212121',
            }
        }
    });

    useEffect(() => {
        setFormValue({...formValue, servicesData: selectedButtons })
    }, [selectedButtons])

    console.log(formValue, 'formValue')
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                    <section className={styles.subContainer}>
                        <div className={styles.titleContainer}>
                            <Typography className={styles.title}>Project Information</Typography>
                            <Typography className={styles.subTitle}>Lorem ipsum dolor sit amet, et nomine patri sanctis.</Typography>
                            <Typography className={styles.address}>Your Address</Typography>
                        </div>
                        <div className={styles.addressContainer}>
                            <section className={styles.addressSubContainer}>
                                <Typography className={styles.addressSubTitle}>{formValue.address}</Typography>
                                <div className={styles.propertyToggleBtn}>
                                    <Chip
                                        label="Single Family Zoning"
                                        deleteIcon={<FamilyZoning />}
                                        variant="outlined"
                                        className={styles.chipBtn}
                                        onDelete={() => { }}
                                    />
                                </div>
                            </section>
                            <section className={styles.propertyContainer}>
                                <Typography className={styles.propertyTitle}
                                >Do you own this property or escrow?</Typography>
                                <div className={styles.propertyToggleBtnContainer}>
                                    <Button variant="outlined"
                                        className={`${formValue.ownProperty === "Yes" ? (styles.ownActiveBtn) : (styles.ownInActiveBtn)}`}
                                        value={formValue.ownProperty}
                                        size="large"
                                        onClick={(e) => { setFormValue({ ...formValue, ownProperty: "Yes", esCrow: "No" }) }}
                                        sx={{ mr: 2 }}
                                    >Own</Button>
                                    <Button variant="outlined"
                                        className={`${formValue.ownProperty === "No" ? (styles.ownActiveBtn) : (styles.ownInActiveBtn)}`}
                                        onClick={(e) => { setFormValue({ ...formValue, esCrow: "Yes", ownProperty: "No" }) }}
                                        size="large"

                                    >Escrow</Button>
                                </div>
                            </section>
                        </div>
                        <div className={styles.residenceContainer}>
                            <Typography className={styles.residenceTitle}>Is it your primary residence or are you an investor? </Typography>
                            <div className={styles.residenceBtnContainer}>
                                <Button variant="outlined" className={`${formValue.residence === "Primary" ? (styles.primaryActiveBtn1) : (styles.primaryInActiveBtn1)}`}
                                    value={formValue.residence}
                                    onClick={(e) => { setFormValue({ ...formValue, residence: "Primary" }) }}
                                    sx={{ mr: 2 }}
                                >Primary</Button>
                                <Button variant="outlined"
                                    className={`${formValue.residence === "Investment" ? (styles.primaryActiveBtn) : (styles.primaryInActiveBtn)}`}
                                    value={formValue.residence}
                                    onClick={(e) => { setFormValue({ ...formValue, residence: "Investment" }) }}
                                >Investment</Button>
                            </div>
                        </div>
                        <div className={styles.goalContainer}>
                            <Typography className={styles.goalTitle}>What's your goal?</Typography>
                            <FormControl size="small" fullWidth sx={{ width: '50%' }}>
                                <ThemeProvider theme={theme}>
                                    <InputLabel>Please select...</InputLabel>
                                    <Select
                                        label='aaaaaaaaaaaa'
                                        className={styles.drop}
                                        displayEmpty
                                    >

                                        <MenuItem value="as soon as possible" className={styles.drop} sx={{ color: '#21212199', }}>Lorem ipsum, dolor</MenuItem>
                                        <MenuItem value='When I close escrow' className={styles.drop} sx={{ color: '#21212199' }}>Lorem ipsum, dolor</MenuItem>
                                        <MenuItem value='Within six months' className={styles.drop} sx={{ color: '#21212199' }}>Lorem ipsum, dolor</MenuItem>
                                        <MenuItem value='Iam not sure' className={styles.drop} sx={{ color: '#21212199' }}>I'am not sure</MenuItem>
                                    </Select>
                                </ThemeProvider>
                            </FormControl>
                        </div>
                        <div className={styles.projectSpecContainer}>
                            <Typography className={styles.projectTitle}>Project Specifications</Typography>
                            <div className={styles.projContainer}>
                                <Typography className={styles.projTitle}>What’s your project start date?</Typography>
                                <FormControl size="small" fullWidth sx={{ width: '50%' }}>
                                    <ThemeProvider theme={theme}>
                                        <InputLabel>Please select...</InputLabel>

                                        <Select
                                            value={formValue.startDate}
                                            label='aaaaaaaaaaaa'
                                            sx={{ color: '#212121' }}
                                            onChange={(e) => { setFormValue({ ...formValue, startDate: e.target.value }) }}
                                            defaultValue={formValue.startDate}
                                            className={styles.drop}
                                            placeholder='a'
                                        >

                                            <MenuItem value="as soon as possible" className={styles.drop} sx={{ color: '#21212199' }}>As soon as Possible</MenuItem>
                                            <MenuItem value='When I close escrow' className={styles.drop} sx={{ color: '#21212199' }}>When I close escrow</MenuItem>
                                            <MenuItem value='Within six months' className={styles.drop} sx={{ color: '#21212199' }}>Within six months</MenuItem>
                                            <MenuItem value='Iam not sure' className={styles.drop} sx={{ color: '#21212199' }}>I'am not sure</MenuItem>
                                        </Select>
                                    </ThemeProvider>
                                </FormControl>
                            </div>
                        </div>
                        <div className={styles.serviceContainer}>
                            <Typography className={styles.serviceTitle}>What services are you interested in?</Typography>
                            <div className={styles.serviceBtnContainer}>
                                <button id="Design" onClick={handleButtonClick} className={`${selectedButtons.includes('Design') ? (styles.selectedBtn1) : (styles.notSelectedBtn1)}`}>Design</button>
                                <button id="Financing" onClick={handleButtonClick} className={`${selectedButtons.includes('Financing') ? (styles.selectedBtn1) : (styles.notSelectedBtn1)}`}>Financing</button>
                                <button id="Construction" onClick={handleButtonClick}className={`${selectedButtons.includes('Construction') ? (styles.selectedBtn1) : (styles.notSelectedBtn1)}`}>Construction</button>
                                <button id="Sale or Purchase" onClick={handleButtonClick} className={`${selectedButtons.includes('Sale or Purchase') ? (styles.selectedBtn2) : (styles.notSelectedBtn2)}`}>Sale or Purchase</button>
                            </div>
                        </div>
                        <div className={styles.btnContainer}>
                            <Button variant="outlined" className={styles.backBtn} onClick={() => setStep(step - 1)}><img src={LeftIcon} alt="" /> BACK </Button>
                            <Button variant="contained" className={styles.nextBtn} onClick={onSubmit}>NEXT <img src={RightIcon} alt="" /></Button>
                        </div>
                    </section>
                </div>
            </Grid>
        </Grid>
    )
}

export default Step3;