import { createStore } from 'redux';

const initialState = {
    formValues: '',
    checkoutUrl: '',
    sessionId: '',
    paymentStatus: '',
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_FORM':
            return {
                ...state,
                formValues: action.payload,
            };
        case 'SET_URL':
            return {
                ...state,
                checkoutUrl: action.payload,
            }
        case 'SET_SESSIONID':
            return {
                ...state,
                sessionId: action.payload,   
            }
        case 'SET_STATUS':
            return {
                ...state,
                paymentStatus: action.payload,
            }
        default:
            return state;
    }
}

const store = createStore(reducer);
export default store;

