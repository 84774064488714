import { Grid } from '@mui/material';
import Order from './order/order';
import Summary from './summary/summary';
import styles from './step.module.scss';
import React from 'react';

const Step7 = ({ stepSevenSubmit, ...other }) => {
    const { formValue, setFormValue, step, setStep } = other;
    
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                    <div className={styles.subContainer}>
                    <section className={styles.innerContainer}>
                    <div className={styles.component}>
                    <Summary setStep={setStep} formValue={formValue} />
                    </div>
                    <div className={styles.component}>
                    <Order formValue={formValue} setFormValue={setFormValue} step={step} setStep={setStep} stepSevenSubmit={stepSevenSubmit} />
                    </div>
                    </section>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default Step7;