import { Grid, Typography, TextField, Button } from "@mui/material";
import React from "react";
import LeftIcon from '../../../assets/icons/LeftIcon.png';
import RightIcon from '../../../assets/icons/RightIcon.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './step.module.scss';

const Step2 = ({ stepTwoSubmit, ...other }) => {

    const { formValue, setFormValue, step, setStep } = other;
    const theme = createTheme({
        palette: {
            primary: {
                main: '#212121',
            }
        }
    });

    const onSubmit = () => {
        stepTwoSubmit();
    };

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>

                <div className={styles.mainContainer}>

                    <section className={styles.subContainer}>
                        <Typography className={styles.title}>
                            Please type in your address
                        </Typography>
                        <Typography className={styles.subTitle}>This is the address where you plan to build your ADU</Typography>
                        <ThemeProvider theme={theme}>
                            <TextField

                                label="Address"
                                focused
                                InputLabelProps={{
                                    style: {
                                        color: '#212121'
                                    }
                                }}
                                inputProps={{ style: { padding: 10 } }}

                                placeholder="4271 J Street, San Diego, CA"
                                value={formValue.address}
                                onChange={(e) =>
                                    setFormValue({ ...formValue, address: e.target.value })}
                            />
                        </ThemeProvider>
                        <div className={styles.btnContainer}>
                            <Button variant="outlined" className={styles.backBtn} onClick={() => setStep(step - 1)}><img src={LeftIcon} alt="" /> BACK </Button>
                            <Button variant="contained" className={styles.nextBtn} onClick={onSubmit}>NEXT <img src={RightIcon} alt="" /></Button>
                        </div>
                    </section>
                </div>
            </Grid>
        </Grid>
    )
}

export default Step2;