import * as React from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)({
    color: '#0F2453',
    height: 3,
    '& .MuiSlider-track': {
        border: 'none',

    },
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        backgroundColor: '#0F2453',

    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1,
        fontSize: 12,
        fontWeight: 'normal',
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '0px',
        backgroundColor: '#000000',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(1%, -100%) rotate(1deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(1deg)',
        },
    },
});


export default function Slide({defaultValue, step, min, max, value, handleChange}) {
    return (
        <PrettoSlider
            valueLabelDisplay="on"
            defaultValue={defaultValue}
            value={value}
            onChange={handleChange}
            step={step}
            marks
            min={min}
            max={max}
        />
    );
}
