import * as React from "react"

const FamilyZoning = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 .5C3.86.5.5 3.86.5 8c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5C15.5 3.86 12.14.5 8 .5Zm0 11.25a.752.752 0 0 1-.75-.75V8c0-.412.338-.75.75-.75s.75.338.75.75v3c0 .412-.338.75-.75.75Zm.75-6h-1.5v-1.5h1.5v1.5Z"
      fill="#fff"
    />
  </svg>
)

export default FamilyZoning