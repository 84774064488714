import React from "react";
import tick from '../../../Icons/tick.png';
import share from '../../../Icons/share.png';
import summryFrame from '../../../Icons/summryFrame.png';
import { Grid, Typography, Card, Button } from '@mui/material';
import styles from './step.module.scss';


const Step8 = ({ step, setStep}) => {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                    <section className={styles.subContainer}>
                        <div className={styles.reportContainer}>
                            <Typography className={styles.reportTitle}>Your report is ready</Typography>
                            <Typography className={styles.reportSubTitle}>You are one step closer to lorem ipsum dolor sit amet.</Typography>
                            <Card className={styles.reportCard}>
                            <img src={tick} alt=''/>
    
                            <Typography className={styles.reportName}>Lorem Ipsum dolor sit amet et nomine </Typography>
                            <section className={styles.shareTitle}>SHARE <img src={share} alt="" /></section>    
                            
                            </Card>
                            <div className={styles.btnContainer}>
                                <Button variant="contained" fullWidth className={styles.consultationBtn}>SCHEDULE A FREE CONSULTATION</Button>
                                <Button variant="outlined" onClick={() => setStep(step - 1)} fullWidth className={styles.downloadBtn}>DOWNLOAD REPORT</Button>
                            </div>
                        </div>
                        <div className={styles.rightImgContainer}>
                            <img src={summryFrame} alt="" />
                        </div>
                    </section>
                </div>
            </Grid>
        </Grid>)
}


export default Step8;