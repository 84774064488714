import React from "react";
import share from '../../Icons/share.png';
import summryFrame from '../../Icons/summryFrame.png';
import { Grid, Typography, Card, Button } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import styles from './cancel.module.scss';


const Cancel = () => {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <div className={styles.mainContainer}>
                    <section className={styles.subContainer}>
                        <div className={styles.reportContainer}>
                            <Typography className={styles.reportTitle}>Payment Failed!</Typography>
                            <Typography className={styles.reportSubTitle}>You are one step closer to lorem ipsum dolor sit amet.</Typography>
                            <Card className={styles.reportCard}>
                                {/* <img src={tick} alt=''/>  */}
                                <CancelOutlinedIcon sx={{ mt: 4, mb: 4, color: 'red' }} />
                                <Typography className={styles.reportName}>Unable to process the payment due to technical issue try again </Typography>
                                <section className={styles.shareTitle}>SHARE <img src={share} alt="" /></section>
                            </Card>
                            <div className={styles.btnContainer}>
                                <Button variant="contained" fullWidth className={styles.consultationBtn}>SCHEDULE A FREE CONSULTATION</Button>
                                 {/* <Button variant="outlined" fullWidth className={styles.downloadBtn}>DOWNLOARD REPORT</Button>  */}
                            </div>
                        </div>
                        <div className={styles.rightImgContainer}>
                            <img src={summryFrame} alt="" />
                        </div>
                    </section>
                </div>
            </Grid>
        </Grid>)
}


export default Cancel;