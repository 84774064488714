import React from 'react';
import styles from './notfound.module.scss';

const PageNotFound = () => {

    return (
        <div className={styles.mainContainer}>
            <h1 className={styles.title}>Page Not Found</h1>
        </div>
    )
}

export default PageNotFound