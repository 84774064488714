import Container from "@mui/material/Container";
import Success from "../../Components/SuccessPage/SuccessPage";
import NavBar from "../../Components/navbar/navbar";
import StepperComp from "../../Components/stepperComp/stepperComp";
import styles from "./success.module.scss";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const SuccessPage = () => {
  const pipedrive = require("pipedrive");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const sessionData = useSelector((state) => state.sessionId);
  const [payStatus, setPayStatus] = useState();
  const [items, setItems] = useState([]);
  let baseUrl ="http://184.72.26.210:3000/transactionDetail/";
  let url = baseUrl.concat(items.transactionId);

  const handlePaymentStatus = () => {
    dispatch({
      type: "SET_STATUS",
      payload: payStatus,
    });
  };

  async function getPaymentStatus() {
    try {
      const response = await axios.get(url);
      setPayStatus(response.data.paymentStatus);
      setIsLoading(false);
      createLead();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("formValues"));
    if (items) {
      setItems(items);
    }
  }, []);

  async function createLead() {
    try {
      const url =
        "https://adugeeks.pipedrive.com/v1/persons?api_token=a3969a80b0dd9b61b5cc251cc2c8fb2afa5f3bb7";
      const contactData = await axios.post(url, {
        name: `${items.firstName} ${items.lastName}`,
        email: [{ value: items.emailId, primary: "true", label: "main" }],
        phone: [{ value: items.phoneNumber, primary: "true", label: "mobile" }],
        add_time: new Date().toISOString(),
        "9382ce149a17094ad30963af41ae7dfbdf24d37e": items.address, //address
        "5fda74bde9560c9eaad14b34db4f37a2ea51c685": items.ownProperty, //Do you own this property or escrow?
        "3d40d7d72fd1c208ab53b13d2d8404a10bf95780": items.esCrow, //Is it your primary residence or are you an investor?
        c46c3f21ab65202b782580c5b8443feca6a2a00f: items.startDate, //What’s your project start date?
        "27f32978f6daa80adaf44024de58143831f98a59": items.servicesData.map((services) =>services), //What services are you interested in?
        "2107875602864870f85085cec37c563346bd36eb": items.aduType1, //ADU type
        b76170f240006d7cd88c02249727de75ed14c3ce: items.aduType2, //Make your selection
        c48b34eabd10ff5afeae1baa8dd5b04a1ca1f5ed: items.aduType3, //Do you know what are you looking specifically?
        "7bb9fe583998b1c4b71cfa09f1d8f18ec37c814f": items.sqft1, //ADU feet
        "1450402bde3b1b94196c61f195c3d0fc4d2109d2": items.sqft2, //JADU feet
        "11c4ee995dde764067ac57365bf9f9c6172f5280": items.aduBedroom, //ADU Bedrooms
        "0838d2fddb0d35a65a98885aed6cffde8edc719b": items.aduBathroom, //ADU Bathrooms
        a5d42eacadc05b4bcd8a20275f244700b1f9a502: items.jaduBedroom, //JADU Bedrooms
        "6a80bc3a3fcf6d661a128b4c397c6bb54620359e": items.jaduBathroom, //JADU Bathrooms
        d7428a78d361df96d801c3074d9d721d838b1a03: items.transactionId, //Transaction Id
        "8f4adde1fc02628ece4759c892342d1073aa39db": payStatus, //Payment status
      });
      console.log(contactData.data);
      if (contactData?.data?.data?.id) {
        console.log(contactData.data.data.id);
        const defaultClient = new pipedrive.ApiClient();
        defaultClient.authentications.api_key.apiKey =
          "a3969a80b0dd9b61b5cc251cc2c8fb2afa5f3bb7";
        console.log("Sending request...");

        const api = new pipedrive.LeadsApi(defaultClient);

        const data = {
          title: contactData.data.data.first_name,
          person_id: contactData.data.data.id,
          visible_to: "1",
          was_seen: true,
        };
        const response = await api.addLead(data);
        console.log("Lead was added successfully!", response);
        localStorage.clear();
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPaymentStatus();
    handlePaymentStatus();
  });

  console.log(payStatus, sessionData);
  return (
    <div className={styles.mainContainer}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <NavBar />
          <div style={{position: "fixed", width: "100%", overFlow: "hidden", zIndex: 1000}}>
          <StepperComp step={3} />
          </div>
          <Container sx={{mt: 18}}>
          <Success />
            {/* {payStatus === "paid" ? <Success /> : <Cancel />} */}
          </Container>
        </>
      )}
    </div>
  );
};

export default SuccessPage;
